import { IPageMenu } from "../../../interfaces"
import styles from './Footer.module.css'
import SubMenu from "./SubMenu"
type props = {
    FooterData:IPageMenu[]
}
export default function Menu({FooterData}:props) {
    return (<>
        {FooterData && FooterData.map((menu, idx) =>
            <div className={styles.linkBoxes} key={idx}>
                <div className={styles.heading}>{menu.pageName}</div>
                <SubMenu menu={menu}/>
            </div>)}
    </>)
}