import styles from "./Footer.module.css"
import footer from '../../../data/footer.json';
import { useGlobalContext } from "../../../lib/context";
export default function Address() {
    const { props: { countryCode, isDubaiPage } } = useGlobalContext();
    const countrycode = isDubaiPage ? 'ae' : countryCode
    const _footerData = footer[countrycode];
    return (<>
        <div className={styles.linkBoxes}>
            <div className={styles.heading}>Contact Us</div>
            <ul>
                <li>Call us on, toll free no. <strong><a href={`tel:${_footerData.contactNo.replace(/\s+/g, "")}`}>{_footerData.contactNo}</a></strong></li>
                <li>Write to us at <br /><a href={`mailto:${_footerData.email}`}>{_footerData.email}</a></li>
            </ul>
        </div>
        <div className={styles.linkBoxes}>
            <div className={styles.heading}>Address</div>
            <ul>
                <li><strong style={{paddingRight:'40px'}}>{_footerData.address}</strong></li>
            </ul>
        </div>

    </>)
}