import { IPageMenu } from "../../../interfaces"
import { NavLink } from "../../ui"

type props = {
    menu: IPageMenu
}
export default function SubMenu({ menu }: props) {
    return <>
        {menu && menu.child && menu.child.map((subMenu, idx) =>
            <ul key={idx}>
                <li><NavLink href={subMenu.pageUrl}>{subMenu.pageName}</NavLink></li>
            </ul>)}
    </>
}