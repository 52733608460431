import { IPageMenu } from "@/interfaces";
import { useGlobalContext } from "@/lib/context";
import FooterAE from "./FooterAE";
import FooterIN from "./FooterIN";

type props = {
    FooterData: IPageMenu[]
}
export default function Footer({ FooterData }: props) {
    const { props: { isDubaiPage } } = useGlobalContext();
    if (isDubaiPage) return <FooterAE FooterData={FooterData} />
    else return <FooterIN FooterData={FooterData} />
}